import React, { forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const AutoshipIcon = forwardRef<SVGSVGElement, SvgIconProps>(function Autoship(
  props,
  ref,
) {
  return (
    <SvgIcon
      fill="none"
      height={24}
      ref={ref}
      viewBox="0 0 24 24"
      width={24}
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M4.01874 20.8193L6.02513 18.2402C8.84548 20.418 14.7839 23.5181 20.5562 17.1147C21.0249 16.5952 21.4874 16.0139 21.9419 15.3595C21.9609 15.3325 21.9808 15.3092 21.9998 15.2817C21.996 15.2836 21.9917 15.2855 21.9879 15.2878C20.0703 16.3905 17.7363 16.9789 15.2465 16.8227C12.2993 16.6379 11.0219 15.9323 9.18929 14.1733L11.2261 11.5548C11.2261 11.5548 6.95401 9.68324 2 11.8221L4.01874 20.8193Z"
        fill="#F27123"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.9811 4.11689L17.9747 6.696C15.1543 4.51817 9.21589 1.41812 3.44365 7.82148C2.97494 8.341 2.5124 8.92226 2.05794 9.57666C2.03894 9.60372 2.019 9.62699 2 9.65454C2.0038 9.65264 2.00807 9.65074 2.01187 9.64836C3.92946 8.54568 6.26352 7.9573 8.75335 8.11353C11.7005 8.29826 12.9779 9.00394 14.8105 10.7629L12.7737 13.3814C12.7737 13.3814 17.0458 15.253 21.9998 13.1141L19.9811 4.11689Z"
        fill="#031657"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
})

export default AutoshipIcon
