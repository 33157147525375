import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BaseUser,
  DateUtils,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import PuiTabs from '~/components/common/PuiTabs'
import LandingWidget, {
  LandingWidgetProps,
} from '~/components/dashboard/landing/widgets/LandingWidget'
import NoRecords from '~/components/dashboard/landing/widgets/NoRecords'
import TableWidget from '~/components/dashboard/landing/widgets/TableWidget'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import i18n from '~/locales/i18n'
import { deleteDiagnosisHistory } from '~/store/actions/medicalHistory'
import {
  fetchWidgetsData,
  getWidgetData,
  getWidgetDataIsLoading,
} from '~/store/duck/landing'
import {
  deleteDiagnosis,
  editDiagnosisStatusOutsideSoap,
  getDiagnosesIsLoading,
} from '~/store/duck/soapDiagnoses'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getDiagnosisProblemStates,
  getFeatureToggle,
  getFindingProblemStates,
} from '~/store/reducers/constants'
import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import {
  DiagnosisSnapshotItem,
  DiagnosisSnapshotItemType,
  MasterProblemsSnapshotItem,
  ProblemDiagnosisLog,
  WidgetColumn,
} from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import DiagnosisNameCell from './DiagnosisNameCell'
import DiagnosisStatusCell from './DiagnosisStatusCell'
import ProblemsDateCell from './ProblemsDateCell'

const columns: WidgetColumn<DiagnosisSnapshotItem>[] = [
  {
    id: 'title',
    component: DiagnosisNameCell,
    width: 3.5,
    label: i18n.t('Common:NAME'),
  },
  {
    id: 'vetName',
    prop: ({ vet }) => Utils.getPersonString(vet),
    width: 2,
    label: i18n.t('Common:TEAM_MEMBER'),
  },
  {
    id: 'date',
    prop: ({ date }) => DateUtils.formatDate(date),
    width: 2.5,
    label: i18n.t('Common:DATE_TIME'),
  },
  {
    id: 'diagnosisStatus',
    component: DiagnosisStatusCell,
    width: 2.5,
    label: i18n.t('Common:STATUS'),
  },
]

const problemsColumns = (clientId: string, patientId: string) => [
  {
    id: 'title',
    component: DiagnosisNameCell,
    width: 2.5,
    label: i18n.t('Common:NAME'),
  },
  {
    id: 'vetName',
    prop: ({ vet }: { vet: BaseUser }) => Utils.getPersonString(vet),
    width: 2,
    label: i18n.t('Common:TEAM_MEMBER'),
  },
  {
    id: 'date',
    width: 2.5,
    component: ProblemsDateCell,
    label: i18n.t('Common:DATE_TIME'),
    componentProps: {
      clientId,
      patientId,
    },
  },
  {
    id: 'diagnosisStatus',
    component: DiagnosisStatusCell,
    width: 2,
    label: i18n.t('Common:STATUS'),
  },
]

const useStyles = makeStyles(
  (theme) => ({
    tabRoot: {
      padding: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
    },
  }),
  { name: 'DiagnosesSnapshot' },
)

interface DiagnosesSnapshotProps
  extends Omit<
    LandingWidgetProps<DiagnosisSnapshotItem>,
    'component' | 'data'
  > {
  clientId: string
  expandedSnapshotType?: SnapshotsAliasTypes
  name: string
  patientId: string
}

const ACTIVE_TAB = {
  id: 0,
  label: i18n.t('Clients:PROBLEMS_WIDGET.ACTIVE_CHRONIC'),
}

const HISTORIC_TAB = {
  id: 1,
  label: i18n.t('Clients:PROBLEMS_WIDGET.HISTORIC'),
}

const DiagnosesSnapshot = ({
  name,
  clientId,
  patientId,
  expandedSnapshotType,
  ...rest
}: DiagnosesSnapshotProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isMasterProblemsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.MASTER_PROBLEMS_WIDGET),
  )

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const diagnoses = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      isMasterProblemsWidgetEnabled
        ? SnapshotsAliasTypes.Problems
        : SnapshotsAliasTypes.Diagnoses,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      isMasterProblemsWidgetEnabled
        ? SnapshotsAliasTypes.Problems
        : SnapshotsAliasTypes.Diagnoses,
    ),
  )
  const isDiagnosesLoading = useSelector(getDiagnosesIsLoading)
  const isDiagnosesHistoryLoading = useSelector(getMedicalHistoryIsSending)
  const DiagnosisStatus = useSelector(getDiagnosisProblemStates)
  const FindingStatus = useSelector(getFindingProblemStates)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const {
    create: diagnoseCreatePermissions,
    delete: diagnoseDeletePermissions,
    read: diagnoseReadPermissions,
    update: diagnoseUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.DIAGNOSE))
  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )

  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)
  const [selectedTab, setSelectedTab] = useState(0)

  const ResolvedDiagnosisStatusId = Utils.findConstantIdByName(
    'Resolved',
    DiagnosisStatus,
  )
  const RemovedDiagnosisStatusId = Utils.findConstantIdByName(
    'Removed',
    DiagnosisStatus,
  )
  const ResolvedFindingStatusId = Utils.findConstantIdByName(
    'Resolved',
    FindingStatus,
  )
  const RemovedFindingStatusId = Utils.findConstantIdByName(
    'Removed',
    FindingStatus,
  )
  const RuledOutDiagnosisStatusId = Utils.findConstantIdByName(
    'Ruled Out',
    DiagnosisStatus,
  )

  const groupedDiagnoses: MasterProblemsSnapshotItem[] = R.curry((problems) =>
    R.pipe(
      R.groupBy((diagnosis: ProblemDiagnosisLog) => diagnosis.problemId),
      R.values,
      R.map(R.sort(R.descend(R.prop('date')))),
      R.map((problemGroup) => ({
        ...R.head(problemGroup),
        children: problemGroup,
      })),
    )(problems),
  )(diagnoses)

  const isResolved = (diagnosis: MasterProblemsSnapshotItem) =>
    (diagnosis.type === DiagnosisSnapshotItemType.PROBLEM_DIAGNOSIS_LOG &&
      (diagnosis.statusId === ResolvedDiagnosisStatusId ||
        diagnosis.statusId === RuledOutDiagnosisStatusId)) ||
    (diagnosis.type === DiagnosisSnapshotItemType.PROBLEM_FINDING_LOG &&
      diagnosis.statusId === ResolvedFindingStatusId)

  const isNotRemoved = (diagnosis: MasterProblemsSnapshotItem) =>
    !(
      (diagnosis.type === DiagnosisSnapshotItemType.PROBLEM_DIAGNOSIS_LOG &&
        diagnosis.statusId === RemovedDiagnosisStatusId) ||
      (diagnosis.type === DiagnosisSnapshotItemType.PROBLEM_FINDING_LOG &&
        diagnosis.statusId === RemovedFindingStatusId)
    )

  const byLatestDate = R.descend((diagnosis: MasterProblemsSnapshotItem) =>
    new Date(diagnosis.date).getTime(),
  )

  const filteredDiagnoses = groupedDiagnoses
    .filter((diagnosis) =>
      selectedTab === ACTIVE_TAB.id
        ? !isResolved(diagnosis)
        : isResolved(diagnosis),
    )
    .filter(isNotRemoved)
    .sort(byLatestDate)

  const refetchDiagnoses = () => {
    dispatch(
      fetchWidgetsData([SnapshotsAliasTypes.Diagnoses], {
        quiet: false,
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        patientId,
      }),
    )
  }

  const refetchDiagnosesAfterChange = useCloseAfterCreation(
    refetchDiagnoses,
    getDiagnosesIsLoading,
    true,
  )
  const refetchDiagnosesAfterHistoryChange = useCloseAfterCreation(
    refetchDiagnoses,
    getMedicalHistoryIsSending,
    true,
  )

  const goToSoap = (item: DiagnosisSnapshotItem) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${item.soapId}`,
        isPatientSharingEnabled ? item.businessId : null,
      ),
    )
  }

  const handleView = (item?: DiagnosisSnapshotItem) => {
    openDiagnosisHistoryDialog({ clientId, patientId, diagnosis: item })
  }

  const handleGoToSoap = goToSoap

  const handleResolve = (item: DiagnosisSnapshotItem) => {
    dispatch(editDiagnosisStatusOutsideSoap(item, ResolvedDiagnosisStatusId))
    refetchDiagnosesAfterChange()
  }

  const handleAddDiagnose = () => {
    openDiagnosisHistoryDialog({ clientId, patientId })
    refetchDiagnosesAfterChange()
  }

  const handleDelete = (item?: DiagnosisSnapshotItem) => {
    if (!item) {
      return
    }
    if (item.soapId) {
      if (item.logId) {
        dispatch(deleteDiagnosis(item.soapId, item.logId))
      }
      refetchDiagnosesAfterChange()
    } else {
      dispatch(deleteDiagnosisHistory(item.id, patientId))
      refetchDiagnosesAfterHistoryChange()
    }
  }

  const getActions = (item?: DiagnosisSnapshotItem) => {
    const isCurrentContextItem =
      !isPatientSharingEnabled || item?.businessId === currentBusinessId

    return [
      !item?.soapId && {
        id: 'view',
        label: t('Common:VIEW_ACTION'),
        Icon: EyeIcon,
        onClick: diagnoseReadPermissions ? () => handleView(item) : undefined,
        disabled: !diagnoseReadPermissions,
      },
      item?.soapId && {
        id: 'goToSoap',
        label: t('Common:GO_TO_SOAP'),
        Icon: ArrowForwardIcon,
        onClick: soapReadPermissions ? () => handleGoToSoap(item) : undefined,
        disabled: !soapReadPermissions,
      },
      item?.soapId &&
        item?.statusId !== ResolvedDiagnosisStatusId &&
        item?.type !== DiagnosisSnapshotItemType.PROBLEM_DIAGNOSIS_LOG &&
        item?.type !== DiagnosisSnapshotItemType.PROBLEM_FINDING_LOG && {
          id: 'resolve',
          label: t('Common:RESOLVE_ACTION'),
          Icon: CheckIcon,
          onClick: diagnoseUpdatePermissions
            ? () => handleResolve(item)
            : undefined,
          disabled: !diagnoseUpdatePermissions,
        },
      isCurrentContextItem &&
        item?.type !== DiagnosisSnapshotItemType.PROBLEM_DIAGNOSIS_LOG &&
        item?.type !== DiagnosisSnapshotItemType.PROBLEM_FINDING_LOG && {
          id: 'delete',
          label: t('Common:DELETE_ACTION'),
          Icon: DeleteIcon,
          onClick: diagnoseDeletePermissions
            ? () => handleDelete(item)
            : undefined,
          disabled: !diagnoseDeletePermissions,
        },
    ].filter(Boolean) as PopperAction[]
  }

  return (
    <LandingWidget
      showHeaderTitles
      showOverflow
      canNavigateToDetails={false}
      canNavigateToItemDetails={R.F}
      columns={
        isMasterProblemsWidgetEnabled
          ? problemsColumns(clientId, patientId)
          : columns
      }
      component={TableWidget}
      data={isMasterProblemsWidgetEnabled ? filteredDiagnoses : diagnoses}
      getActions={getActions}
      isLoading={isLoading || isDiagnosesLoading || isDiagnosesHistoryLoading}
      name={name}
      noRecords={
        <NoRecords
          action={t('Common:ADD_DIAGNOSIS_HISTORY')}
          text={t('Clients:SNAPSHOTS_AND_RECORDS.NO_DIAGNOSES')}
          onClick={diagnoseCreatePermissions ? handleAddDiagnose : undefined}
        />
      }
      showPaddings={false}
      tabSection={
        isMasterProblemsWidgetEnabled ? (
          <PuiTabs
            classes={{ tabRoot: classes.tabRoot }}
            selectedTab={selectedTab}
            tabs={[ACTIVE_TAB, HISTORIC_TAB]}
            variant="standard"
            onSelectedTabChange={setSelectedTab}
          />
        ) : undefined
      }
      {...rest}
    />
  )
}

export default DiagnosesSnapshot
